import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    private count = 0;

    constructor(private loadingService: LoadingService) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        this.count++;
        this.loadingService.setLoadingStatus(true);

        return next.handle(req).pipe(
            finalize(() => {
                this.count--;
                if (this.count === 0) {
                    this.loadingService.setLoadingStatus(false);
                }
            }),
        );
    }
}
