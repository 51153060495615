import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { CoreModule } from './core/core.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {MAT_DATE_LOCALE} from "@angular/material/core";

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(CoreModule, CommonModule, TranslateModule),
    provideRouter(routes),
    provideAnimationsAsync(),
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
  ],
};
