import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/home/home.routes').then(m => m.routes),
  },
  {
    path: 'orders',
    loadChildren: () => import('./features/orders/orders.routes').then(m => m.routes),
  },
  {
    path: 'restaurants',
    loadChildren: () => import('./features/restaurants/restaurants.routes').then(m => m.routes),
  },
  {
    path: 'products',
    loadChildren: () => import('./features/products/products.routes').then(m => m.routes),
  },
  {
    path: 'categories',
    loadChildren: () => import('./features/categories/categories.routes').then(m => m.routes),
  },
  {
    path: 'ingredients',
    loadChildren: () => import('./features/ingredients/ingredients.routes').then(m => m.routes),
  },
  {
    path: 'users',
    loadChildren: () => import('./features/users/users.routes').then(m => m.routes),
  },
  {
    path: 'cart',
    loadChildren: () => import('./features/cart/cart.routes').then(m => m.routes),
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.routes').then(m => m.routes),
  },
  {
    path: 'session',
    loadChildren: () => import('./features/session/session.routes').then(m => m.routes),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./features/dashboard/dashboard.routes').then(m => m.routes),
  },
  { path: '**', redirectTo: '' },
];
