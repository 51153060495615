import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

    constructor(private translate: TranslateService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const lang = this.translate.currentLang;
        if (lang) {
            req = req.clone({
                setHeaders: {
                    'X-Preferred-Language': lang,
                },
            });
        }
        return next.handle(req);
    }
}
